import React, { Component } from 'react'

import CV from 'react-cv'
 
class App extends Component {
  render () {
    return (
      <CV
        personalData={{
          name: 'Sam Ng',
          title: 'Fixed Wireless Integration Engineer',
          image: 'https://itsmecvxyz.s3.us-west-000.backblazeb2.com/1516604839654.png',
          contacts: [
            { type: 'email', value: 'samuel.ys.ng@outlook.com'},
            { type: 'location', value: 'Melbourne' },
            { type: 'website', value: 'itsme.samng.xyz' },
            { type: 'linkedin', value: 'https://www.linkedin.com/in/samuelysng/' },
            { type: 'github', value: 'https://github.com/shubu000' }
        ]}}
        sections= {
          [
            {
              type: 'experiences-list',
              title: 'Experiences',
              icon: 'cubes',
              items: [
                {
                  title: 'Senior Engineer - Fixed Wireless',
                  company: 'NBN Co - Fixed Wireless',
                  description: 'Assess and supervise changes to NBN Fixed Wireless test environment',
                  companyWebSite: 'https://www.nbnco.com.au/learn/network-technology/fixed-wireless-explained',
                  datesBetween: '2021.04 - Present',
                },
                {
                  title: '',
                  company: '',
                  description: 'Improve network monitoring and automate repetitive tasks via Python, present network information through nocoDB',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: '',
                  company: '',
                  description: 'Coordinate initial deployment of NCS 540 routers and Cisco Network Controller into NTF',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: '',
                  company: '',
                  description: 'Liaise with change management and the vendor for network changes',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: 'Test Environment Technical Co-ordinator - NTF',
                  company: 'NBN Co',
                  description: 'Manage network resources and identify integration issues in NBN\'s technology stack',
                  companyWebSite: 'https://www.nbnco.com.au/',
                  datesBetween: '2020.01 - 2021.04',
                },
                {
                  title: '',
                  company: '',
                  description: 'Manage internal and external stakeholders',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: '',
                  company: '',
                  description: 'Codify existing practices and automate repetitive tasks',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: 'Senior Integration Engineer',
                  company: 'Ericsson Australia',
                  description: 'Primary technical advisor on the projects facing the NBN',
                  companyWebSite: 'https://www.ericsson.com/en/about-us/company-facts/ericsson-worldwide/australia',
                  datesBetween: '2018.02 - 2020.01'
                },
                {
                  title: '',
                  company: '',
                  description: 'Mentor and identify potential new team members',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: '',
                  company: '',
                  description: 'Perform project co-ordination activities',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: '',
                  company: '',
                  description: 'Established the use of sharepoint and created a centralised workflow for the team',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: 'Business Builder',
                  company: 'Ericsson Australia',
                  description: ' Engaged with customers at tradeshows and customers events as an Ericsson ambassador',
                  companyWebSite: 'https://www.ericsson.com/en',
                  datesBetween: '2018.10 - 2020.01'
                },
                {
                  title: '',
                  company: '',
                  description: 'Managed and presented various demonstrations for visiting business leaders to the Ericsson Innovation Centre to understand Ericsson’s key marketing messages',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: 'Integration Engineer',
                  company: 'Ericsson Australia',
                  description: 'Implement network changes',
                  companyWebSite: 'https://www.ericsson.com/en',
                  datesBetween: '2016.02 - 2018.02'
                },
                {
                  title: '',
                  company: '',
                  description: 'Perform testing for new products and updates',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: 'Transmission Designer',
                  company: 'Ericsson Australia',
                  description: 'Design mobile backhaul optical transmission',
                  companyWebSite: 'https://www.ericsson.com/en/portfolio/networks/ericsson-radio-system/mobile-transport',
                  datesBetween: '2015.01 - 2016.02'
                },
                {
                  title: '',
                  company: '',
                  description: 'Co-ordinate Field technicians for changes',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: 'Instructor',
                  company: 'Ericsson Australia',
                  description: 'Produce e-learning materials for Ericsson\'s optical transport offering',
                  companyWebSite: 'https://www.ericsson.com/en/portfolio/training-offerings',
                  datesBetween: '2013.12 - 2015.04'
                },
                {
                  title: '',
                  company: '',
                  description: 'Adapt cirriculum and align material to customer\'s internal processes',
                  companyWebSite: '',
                  datesBetween: '',
                },
                {
                  title: 'Graudate',
                  company: 'Ericsson Australia',
                  description: 'Perform UAT tesing and update documentation',
                  companyWebSite: 'https://www.ericsson.com/en/portfolio/networks/ericsson-radio-system/mobile-transport',
                  datesBetween: '2011.02 - 2013.12'
                }
              ]
            },
            {
              type: 'projects-list',
              title: 'Projects',
              description: 'List of projects and products that I have worked on',
              icon: 'tasks',
              groups: [
                {
                  sectionHeader: 'NBN Co',
                  items: [
                    { title: 'NTF (National Test Facility) network monitoring tool (codename:Wong)', projectUrl: '', description: 'In house developed network monitoring tool' },
                    { title: ' ', projectUrl: '', description: 'Extract network element infomation via Python (through paramiko)' },
                    { title: ' ', projectUrl: '', description: 'Elements include: Nokia SROS Switches, Ericsson EPG, MME and CUDB' },
                    { title: ' ', projectUrl: '', description: 'Network information is stored in mySQL container' },
                    { title: ' ', projectUrl: 'https://github.com/nocodb/nocodb', description: 'Use NocoDb as frontend for the data and API server for the mysql backend' },
                    { title: ' ', projectUrl: '', description: 'This project is deployed using docker-compose' },
                  ]
                },
                {
                  sectionHeader: 'Ericsson Australia',
                  items: [
                    { title: 'Introduction of EPG into NBN network', projectUrl: 'https://www.ericsson.com/en/portfolio/digital-services/cloud-core/cloud-packet-core/evolved-packet-gateway', description: 'Increase network capacity for NBN' },
                    { title: ' ', projectUrl: '', description: 'Ensure that EPGs were installed and integrated on time' },
                    { title: ' ', projectUrl: '', description: 'Optimise delievery process and saved costs' },
                    { title: 'Introduction of CEE into NBN network', projectUrl: 'https://www.ericsson.com/en/portfolio/digital-services/cloud-infrastructure/cloud-execution-environment', description: 'Introduced the first virtualised function into fixed wireless network for NBN' },
                    { title: ' ', projectUrl: '', description: 'Designed resource allocation for VMs to maximise efficiency' },
                    { title: ' ', projectUrl: '', description: 'Deployed openstack NFV into the NBN network' },
                    { title: ' ', projectUrl: '', description: 'Integrate new VMs into existing environment, modifying existing Juniper firewall, updating routing for corresponding VRFs' },
                  ]
                }
              ]
            },
            {
              type: 'projects-list',
              title: 'Hobbies',
              description: 'List of projects I work on outside of work',
              icon: 'Book',
              groups: [
                {
                  sectionHeader: 'Home Network',
                  items: [
                    { title: 'Home Network', projectUrl: 'https://github.com/shubu000/homnet-xyz', description: 'Docker applications for my home network' },
                    { title: 'Traefik', projectUrl: 'https://traefik.io/traefik/', description: 'Reverse Proxy/Load balancer for the network, auto install https certs into internal apps' },
                    { title: 'Pihole', projectUrl: 'https://pi-hole.net', description: 'DNS for the home network' },
                  ]
                },
                {
                  sectionHeader: 'Unifi wifi network dimensioning',
                  items: [
                    { title: 'Introduction of Unifi access points into my local church\'s network', projectUrl: '', description: 'Simplified and streamline network' },
                    { title: '', projectUrl: '', description: 'Seperate SSIDs for hired staff, occasional volunteers and guests' },
                  ]
                },
                {
                  sectionHeader: 'This web CV',
                  items: [
                    { title: 'The website is created through reactcv' },
                    { title: '', projectUrl: '', description: 'This page is deployed using cloudflare pages which monitors github for any change in code' },
                  ]
                }
              ]
            },
            {
              type: 'common-list',
              title: 'Education',
              icon: 'graduation',
              items: [
                {
                  title: 'Bachelor of Engineering & Bachelor of Commerce',
                  authority: 'University of Melbourne',
                  authorityWebSite: 'https://www.unimelb.edu.au',
                  rightSide: '2003 - 2008'
                },
                {
                  title: 'Master of Engineering (sustainable energy)',
                  authority: 'RMIT University, Melbourne',
                  authorityWebSite: 'https://www.rmit.edu.au',
                  rightSide: '2009 - 2010'
                }
              ]
            }
          ]
        }
        branding={false} // or false to hide it.
      />
    )
  }
}

export default App;
